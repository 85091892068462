@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'), url(./assets/fonts/GT-Walsheim-Light-Trial.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'), url(./assets/fonts/GT-Walsheim-Regular-Trial.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'), url(./assets/fonts/GT-Walsheim-Medium-Trial.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'), url(./assets/fonts/GT-Walsheim-Bold-Trial.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.company-logo {
  border-radius: 50%;
}

.resume-heading {
  text-align: center;
}

.section-heading {
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 40px;
}

.section-subheading {
  margin-bottom: 75px;
  text-transform: none;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.timeline-date {
  text-transform: uppercase;
}

.text-muted {
  color: #777;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dot-blue {
  color: #5f99ff !important;
}

.dot-white {
  color: white !important;
}



.hero-text {
  font-family: "GT-Walsheim", "Montserrat", "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 91px;
}

.hero-subtext {
  font-family: "GT-Walsheim", "Montserrat", "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 28px;
}

.text-loop * {
  color: #5f99ff;
  font-size: 48px;
}

.skill-card {
  background-color: #282c34 !important;
  margin-bottom: 20px;
  padding: 0px 20px;
}

.skills-logo {
  margin: 0 auto;
  max-height: 100px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.skill {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  column-width: 100px;
}

.entry-image {
  max-width: 90vw;
  max-height: 75vh;
}

.tab-label {
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .hero-intro {
    width: 100vh;
  }

  .hero-box {
    position: absolute;
  }
}